/*------------------------------------------------------------------------------

  Template Name: Subash Ecommerce Responsive Bootstrap Template
  Description: This is html5 template
  Author: codecarnival
  Version: 1.0
  Design and Developed by: codecarnival

Note: This color style file includes all external color css file.

-------------------------------------------------------------------------------*/


/* --------------------------------------------------
    Template color css file
-------------------------------------------------- */

/*1*/
/*@import url("skin-amber.css");*/

/*2*/
/*@import url("skin-blue-grey.css");*/

/*3*/
/*@import url("skin-blue.css");*/

/*4*/
/*@import url("skin-cyan.css");*/

/*5*/
/* @import url("skin-default.css"); */

/*6*/
/*@import url("skin-green.css");*/

/*7*/
/*@import url("skin-purple.css");*/

/*8*/
