/*------------------------------------------------------------------------------

  Template Name: Subash Ecommerce Responsive Bootstrap Template
  Description: This is html5 template
  Author: codecarnival
  Version: 1.0
  Design and Developed by: codecarnival

Note: This core style file includes all external plugins css file.

-------------------------------------------------------------------------------*/


/* --------------------------------------------------
    Fonts 
-------------------------------------------------- */

/* --------------------------------------------------
    Plugins 
-------------------------------------------------- */

/* --------------------------------------------------
    Shortcode 
-------------------------------------------------- */