/*
  Template Name: Subash Ecommerce Responsive Bootstrap Template
  Description: This is html5 template
  Author: codecarnival
  Version: 1.0
  Design and Developed by: codecarnival
  NOTE: This is main style sheet of template, 
  This file contains the styling for the actual Template. 
  Please do not change anything here! write in a custom.css file if required!

*/
/*================================================
[  Table of contents  ]
================================================
  1.General
  2. transtion
  3. button
    3.1 button small
    3.2 buttons variation
  4. social-icon
  5. Text color
  6. Input 
  7. submit button hover 
  8. back-to-top 
  9. slick-arrow 
    9.1 slick-arrow-1
    9.2 slick-arrow-2
    9.3 slick-arrow-3
  10. slick-dots
    10.1 slick-dots-1
  11. quick view (modal)  
  12. Basic margin padding  
    12.1 Margin top 
    12.2 Margin right  
    12.3 Margin bottom 
    12.4 Margin left 
    12.5 Padding top 
    12.6 Padding right   
    12.7 Padding bottom    
    12.8 Padding left  
    12.9 Page section padding    
    12.10 Page section margin     
    12.11 Extra padding & margin
  13. Scroll Up 
  14. Extra class (Custom cloumn)
  15. box-shadow 
  16. Background variation set 
  17. Opacity background
  18. image background
  19. tooltip
  20. separator
  21. Section title 1   
  22. section title 2 

======================================
[ End table content ]
======================================*/

/*************************
  1.General
*************************/
body {
  background: #fff;
  font-family: 'Raleway', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
}
.img{
  max-width: 100%;
  transition: all 0.3s ease-out 0s;
}
a,
.btn {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.btn {
  border-radius: 0px;
  font-size: 14px;
  padding: 0px 15px;
  height: 30px;
  line-height: 30px;
}
a:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
}
a:focus,
a:hover {
  color: #ff7f00;
  text-decoration: none;
}
a,
button,
input {
  outline: medium none;
  color: #666666;
}
.uppercase { 
  text-transform: uppercase
}
.capitalize { 
  text-transform: capitalize
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif;
  font-weight: normal;
  color: #434343;
  margin-top: 0px;
  font-style: normal;
  font-weight: 400;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a { 
  color: inherit 
}
h1 {
  font-size: 30px;
  font-weight: 500;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
ul {
  margin: 0px;
  padding: 0px;
}
li { 
  list-style: none; 
}
p {
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  color: #999999;
  margin-bottom: 15px;
}
hr {
  border-bottom: 1px solid #eeeeee;
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}
hr.style-2{
  border-bottom: 1px dashed #eceff8;
}
hr.mp-0 {
  margin: 0;
  border-bottom: 1px solid #eceff8;
}
hr.mtb-40 {
  margin: 40px 0;
  border-bottom: 1px solid #eceff8;
  border-top: 0px;
}
label {
  color: #999;
  font-size: 14px;
  font-weight: 400;
}
*::-moz-selection {
  background: #4fc1f0;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background: #4fc1f0;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #4fc1f0;
  color: #fff;
  text-shadow: none;
}
.mark, mark {
  background: #ff7f00 none repeat scroll 0 0;
  color: #fff;
}
span.tooltip-content {
  color: #ff7f00;
  cursor: help;
  font-weight: 600;
}
.f-left {
  float: left
}
.f-right {
  float: right
}
.fix {
  overflow: hidden
}
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
.g-font-1 {
  font-family: "Raleway",sans-serif;
}
.g-font-2 {
  font-family: 'Roboto', sans-serif;
}
.font-w-5 {
  font-weight: 500;
}
.font-w-6 {
  font-weight: 600;
}
.font-w-7 {
  font-weight: 700;
}
.panel-group .panel {
  border-radius: 0;
  margin-bottom: 0;
}
.my-account-content .panel-body {
  padding: 0;
}
.panel {
  border-radius: 0;
}
/***************************
  2. transtion
****************************/
a.button::after,
a.button-small::after {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.share ul,
.share:hover ul {
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
a.button-border span,
a.button-border-white span,
input,
select,
textarea {
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

/*************************
  3. button
*************************/
a.button {
  background: #ff7f00;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03) inset;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  margin-right: 10px;
  overflow: hidden;
  padding: 0;
  position: relative;
}
a.button i {
  background: rgba(0, 0, 0, 0.09);
  display: block;
  float: left;
  padding: 14px;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  width: 50px;
  text-align: center;
}
a.button span {
  display: block;
  float: left;
  position: relative;
  z-index: 2;
  padding: 11px 20px;
  font-size: 15px;
  color: #fff;
  font-weight: normal;
}
a.button::after {
  background: rgba(0, 0, 0, 0.09) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  z-index: 1;
}
a.button:hover::after { width: 100% }
a.button.button-white {
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03) inset;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  overflow: hidden;
  padding: 0;
  position: relative;
  background-color: #fff;
  color: #4FC1F0;
  font-size: 15px;
}
a.button.button-white i {
  background: rgba(0, 0, 0, 0.09);
  display: block;
  float: left;
  padding: 14px;
  font-size: 16px;
  color: #4FC1F0;
  font-weight: normal;
  width: 50px;
  text-align: center;
}
a.button.button-white span {
  display: block;
  float: left;
  position: relative;
  z-index: 2;
  padding: 11px 20px;
  font-size: 15px;
  color: #434343;
  font-weight: normal;
}
a.button.button-grey {
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03) inset;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  overflow: hidden;
  padding: 0;
  position: relative;
  background-color: #eceff7;
  color: #4FC1F0;
  font-size: 15px;
}
a.button.button-grey i {
  background: rgba(0, 0, 0, 0.09);
  display: block;
  float: left;
  padding: 14px;
  font-size: 16px;
  color: #4FC1F0;
  font-weight: normal;
  width: 50px;
  text-align: center;
}
a.button.button-grey span {
  display: block;
  float: left;
  position: relative;
  z-index: 2;
  padding: 11px 20px;
  font-size: 15px;
  color: #4FC1F0;
  font-weight: normal;
}
a.button.button-black {
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03) inset;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  overflow: hidden;
  padding: 0;
  position: relative;
  background-color: #575757;
  color: #4FC1F0;
  font-size: 15px;
}
a.button.button-black i {
  background: rgba(0, 0, 0, 0.3);
  display: block;
  float: left;
  padding: 14px;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  width: 50px;
  text-align: center;
}
a.button.button-black span {
  display: block;
  float: left;
  position: relative;
  z-index: 2;
  padding: 11px 20px;
  font-size: 15px;
  color: #fff;
  font-weight: normal;
}
a.button-border span {
  border: 0 none;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 11px 20px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  color: #4FC1F0;
  font-size: 15px;
  border: 1px solid #4FC1F0;
}
a.button-border span:hover {
  box-shadow: -200px 0 0 #4FC1F0 inset;
  color: #fff;
  border-color: #4FC1F0;
}
a.button-border-white span {
  border: 0 none;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 11px 20px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  color: #fff;
  font-size: 15px;
  border: 1px solid #fff;
}
a.button-border-white span:hover {
  box-shadow: -200px 0 0 #fff inset;
  color: #4FC1F0;
  border-color: #fff;
}
/*************************
    3.1 button small
*************************/
a.button-small {
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03) inset;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  overflow: hidden;
  padding: 0;
  position: relative;
  background-color: #4FC1F0;
  color: #fff;
  font-size: 15px;
}
a.button-small i {
  background: rgba(0, 0, 0, 0.09);
  display: block;
  float: left;
  padding: 10px;
  font-size: 13px;
  color: #fff;
  font-weight: normal;
  width: 40px;
  text-align: center;
}
a.button-small span {
  display: block;
  float: left;
  position: relative;
  z-index: 2;
  padding: 8px 10px;
  font-size: 13px;
  color: #fff;
  font-weight: normal;
}
a.button-small:after {
  background: rgba(0, 0, 0, 0.09) none repeat scroll 0 0;
  content: "";
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: 1;
}
a.button-small:hover::after { width: 100% }
/*************************
    3.2 buttons variation
*************************/
a.button.large {margin-right: 0px; }

a.button.large span { padding: 16px 80px; font-size: 18px;  }
a.button.large i { padding: 20px; width: 60px; font-size: 18px; }

a.button.medium span { padding: 14px 60px; font-size: 16px; }
a.button.medium i { padding: 17px; width: 50px; font-size: 16px; }

a.button.small span { padding: 12px 40px; }
a.button.small i { padding: 14px; width: 45px; }

a.button.small span { padding: 12px 40px; }
a.button.small i { padding: 15px; width: 45px; }

a.button.extra-small span { padding: 8px 30px; font-size: 14px; }
a.button.extra-small i { padding: 11px; width: 38px; font-size: 13px; }

/*************************
  4. social-icon
*************************/
.social-icon ul { margin: 0; padding: 0; }
.social-icon ul li { display: inline-block; list-style: none; }
.social-icon ul li a { display: block; margin: 3px; width: 50px; height: 50px; line-height: 50px; font-size: 16px; color: #fff; background: #4FC1F0; text-align: center; border-radius: 50%; }
.social-icon ul li a:hover { background: #EB3B60; }

.social-icon.socile-icon-style-2 ul li a { color: #fff; background: #323232; border-radius: 0;}
.social-icon.socile-icon-style-2 ul li a:hover { background: #EB3B60;  }

.social-icon.socile-icon-style-3 ul li a { color: #fff; background: transparent; border:1px solid #fff; }
.social-icon.socile-icon-style-3 ul li a:hover { background: #EB3B60; border-color:#EB3B60; }


/*************************
  5. Text color
*************************/
.text-blue { color: #4FC1F0; }
.text-white { color: #fff; }
.text-black-1 { color: #575757; }
.text-black-2 { color: #737373; }
.text-black-3 { color: #434343; }
.text-black-4 { color: #999; }
.text-black-5 { color: #666; }
.text-gray { color: #666; }
.text-theme { color: #ff7f00; }

/*************************
  6. Input 
*************************/
input[type="text"],
input[type="password"] {
  background: #fff none repeat scroll 0 0;
  border: 1px solid transparent;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  color: #999999;
  font-size: 13px;
  height: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
  width: 100%;
}
button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  font-family: raleway;
}
select {
  border: 1px solid #eeeeee;
  color: #999999;
  font-size: 13px;
  height: 30px;
  padding-left: 20px;
}
option {
  background: #ffffff none repeat scroll 0 0;
  border: 0 solid #626262;
  font-size: 13px;
  padding-left: 10px;
}
input[type="text"]:focus,
input[type="password"]:focus {
    border: 1px solid #FFBF7F;
}
textarea {
  background: #ffffff none repeat scroll 0 0;
  border: 1px solid transparent;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  padding: 10px 10px 10px 20px;
  resize: vertical;
  width: 100%;
}
textarea:focus {
  border-color: #ff7f00;
  outline: none;
}
.height-80 {
    height: 80px;
}
::-moz-placeholder {
    color: #444;
    font-size: 13px;
}
.submit-btn-1 {
  background: rgb(255, 127, 0) none repeat scroll 0 0;
  border: medium none;
  color: rgb(255, 255, 255);
  font-size: 13px;
  font-weight: 700;
  height: 35px;
  padding: 0 20px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

/*************************
  7. submit button hover 
*************************/
.btn-hover-1,
.btn-hover-2 {
  position: relative;
}
.btn-hover-1::before,
.btn-hover-2::before {
  background: #434343 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: all 0.5s ease 0s;
  width: 0;
  z-index: 9999;
}
.btn-hover-1:hover::before,
.btn-hover-2:hover::before {
  width: 100%;
}
.btn-hover-2::before {
  background: #ff7f00 none repeat scroll 0 0;
}
/*************************
  8. back-to-top 
*************************/
#back-to-top .top {
  z-index: 999;
  position: fixed;
  margin: 0px;
  color: #fff;
  transition: all .5s ease-in-out;
  position: fixed;
  bottom: 105px;
  right: 15px;
  border-radius: 3px;
  z-index: 999;
  background: transparent;
  font-size: 14px;
  background: #4FC1F0;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 38px;
}
#back-to-top .top:hover {
  color: #fff;
  height: 50px;
}
#back-to-top .top { background: #007da1; }
/*************************
  9. slick-arrow 
*************************/
/* 9.1 slick-arrow-1 */
.slick-arrow-1 .slick-arrow {
  color: #ccc;
  font-size: 40px;
  left: -60px;
  line-height: 20px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  transition: all 0.3s ease 0s;
  z-index: 999;
}
.slick-arrow-1 .arrow-next.slick-arrow {
  left: auto;
  right: -60px;
}
.slick-arrow-1 .slick-arrow:hover {
  color: #ff7f00;
}
/* 9.2 slick-arrow-2 */
.slick-arrow-2 .slick-arrow {
  border: 1px solid #ddd;
  color: #999999;
  font-size: 20px;
  height: 35px;
  left: -30px;
  line-height: 35px;
  margin-top: -17px;
  opacity: 0;
  position: absolute;
  right: auto;
  top: 50%;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 35px;
  z-index: 2;
}
.slick-arrow-2 .arrow-next.slick-arrow {
  left: auto;
  right: -30px;
}
.slick-arrow-2:hover .slick-arrow {
    opacity: 1;
    visibility: visible;
}
.slick-arrow-2 .arrow-prev.slick-arrow:hover, .slick-arrow-2 .arrow-next.slick-arrow:hover {
  color: #FF7F00;
  border-color: #FF7F00;
}
/* 9.3 slick-arrow-3 */
.slick-arrow-3 .slick-arrow {
  color: #999999;
  font-size: 20px;
  height: 35px;
  left: 0;
  line-height: 35px;
  margin-top: -17px;
  position: absolute;
  right: auto;
  top: 50%;
  transition: all 0.3s ease 0s;
  width: 35px;
  z-index: 2;
}
.slick-arrow-3 .arrow-next.slick-arrow {
  left: auto;
  right: 0px;
}
.slick-arrow-3:hover .slick-arrow {}
.slick-arrow-3 .arrow-prev.slick-arrow:hover, .slick-arrow-3 .arrow-next.slick-arrow:hover {
  color: #FF7F00;
}
/*************************
  10. slick-dots
*************************/
/* 10.1 slick-dots-1 */
.slick-dots-1 .slick-dots {
  bottom: 70px;
  position: absolute;
  right: 30px;
}
.slick-dots-1 .slick-dots li {
  background: #cccccc none repeat scroll 0 0;
  border-radius: 50%;
  float: left;
  height: 8px;
  margin: 0 5px;
  width: 8px;
}
.slick-dots-1 .slick-dots li button {
  display: none;
}
.slick-dots-1 .slick-dots li.slick-active {
  background: #FF7F00;
}
/*************************
  11. quick view (modal)
*************************/
#productModal .modal-dialog {
  margin: 5% auto;
  max-width: 96%;
  min-height: 300px;
  padding: 20px;
  width: 870px;
}
#quickview-wrapper .modal-content {
  border-radius: 0;
}
#productModal .modal-header {
  border: 0 none;
  padding: 0;
}
#productModal button.close {
  position: absolute;
  right: 10px;
  top: 10px;
  transition: all 0.3s ease 0s;
  z-index: 2;
}
#productModal button.close span {
  border: 1px solid #909295;
  border-radius: 60px;
  color: #909295;
  display: block;
  height: 30px;
  line-height: 25px;
  padding-top: 1px;
  text-align: center;
  width: 30px;
}
#productModal button.close:hover {
  opacity: 1;
}
.modal-product .product-images {
  float: left;
  width: 40%;
}
.modal-product .product-images .main-image {
  width: 100%;
}
.modal-product .product-images .main-image img {
  width: 100%;
}
.modal-product .product-info {
  background: #ffffff none repeat scroll 0 0;
  float: left;
  padding: 15px 15px 18px 30px;
  text-align: left;
  width: 60%;
}
.modal-product .product-info h1 {
  color: #666;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin: 0 0 20px;
  text-transform: uppercase;
}
.s-price-box {
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  display: block;
  padding: 10px 0;
}
.s-price-box .new-price {
  color: #666;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
}
.s-price-box .old-price {
  color: #a6a6a6;
  font-size: 15px;
  text-decoration: line-through;
}
.modal-product .product-info .see-all {
  color: #42414f;
  display: inline-block;
  margin: 15px 0 20px;
  text-decoration: underline;
}
.modal-product .product-info .quick-add-to-cart {
  border-bottom: 1px solid #e5e5e5;
  float: left;
  margin: 0 0 15px;
  padding: 0 0 30px;
  width: 100%;
}
.quick-add-to-cart .numbers-row > input {
  border: 1px solid #e5e5e5;
  float: left;
  height: 45px;
  text-align: center;
  width: 100px;
}
.quick-add-to-cart .single_add_to_cart_button {
  background: #666 none repeat scroll 0 0;
  border: medium none;
  border-radius: 3px;
  color: #ffffff;
  float: left;
  font-weight: 700;
  height: 45px;
  margin-left: 15px;
  padding: 0 55px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
.quick-add-to-cart .single_add_to_cart_button:hover {
  background: #FF7F00 none repeat scroll 0 0;
}
.modal-product .product-info .quick-desc {
  border-bottom: 1px solid #e5e5e5;
  color: #909295;
  margin-bottom: 10px;
  padding: 0 0 15px;
}
.widget-title-modal {
  color: #42414f;
  font-size: 16px;
  line-height: 30px;
  margin: 0 0 5px;
  text-transform: none;
  width: 100%;
}
.modal-product .widget .social-icons li {
  float: left;
  margin-right: 10px;
}
.modal-product .widget .social-icons li a {
  border: 1px solid #e5e5e5;
  color: #909295;
  display: block;
  font-size: 18px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  width: 40px;
}
.modal-product .widget .social-icons li a:hover {
  color: #ffffff;
}

/*************************
  12. Basic margin padding  
*************************/
.m-0 {
    margin: 0;
}
.p-0 {
    padding: 0;
}
/*************************
    12.1 Margin top 
*************************/
.mt-0 { margin-top: 0 }
.mt-10 { margin-top: 10px }
.mt-15 { margin-top: 15px }
.mt-20 { margin-top: 20px }
.mt-30 { margin-top: 30px }
.mt-35 { margin-top: 35px }
.mt-40 { margin-top: 40px }
.mt-50 { margin-top: 50px }
.mt-60 { margin-top: 60px }
.mt-70 { margin-top: 70px }
.mt-80 { margin-top: 80px }
.mt-90 { margin-top: 90px }
.mt-100 { margin-top: 100px }
.mt-110 { margin-top: 110px }
.mt-120 { margin-top: 120px }
.mt-130 { margin-top: 130px }
.mt-140 { margin-top: 140px }
.mt-150 { margin-top: 150px }
/*************************
    12.2 Margin right  
*************************/
.mr-0 { margin-right: 0px }
.mr-10 { margin-right: 10px }
.mr-15 { margin-right: 15px }
.mr-20 { margin-right: 20px }
.mr-30 { margin-right: 30px }
.mr-40 { margin-right: 40px }
.mr-50 { margin-right: 50px }
.mr-60 { margin-right: 60px }
.mr-70 { margin-right: 70px }
.mr-80 { margin-right: 80px }
.mr-90 { margin-right: 90px }
.mr-100 { margin-right: 100px }
.mr-110 { margin-right: 110px }
.mr-120 { margin-right: 120px }
.mr-130 { margin-right: 130px }
.mr-140 { margin-right: 140px }
.mr-150 { margin-right: 150px }
/*************************
    12.3 Margin bottom 
*************************/
.mb-0 { margin-bottom: 0 }
.mb-5 { margin-bottom: 5px }
.mb-10 { margin-bottom: 10px }
.mb-15 { margin-bottom: 15px }
.mb-20 { margin-bottom: 20px }
.mb-25 { margin-bottom: 25px }
.mb-30 { margin-bottom: 30px }
.mb-35 { margin-bottom: 35px }
.mb-40 { margin-bottom: 40px }
.mb-50 { margin-bottom: 50px }
.mb-60 { margin-bottom: 60px }
.mb-70 { margin-bottom: 70px }
.mb-80 { margin-bottom: 80px }
.mb-90 { margin-bottom: 90px }
.mb-100 { margin-bottom: 100px }
.mb-110 { margin-bottom: 110px }
.mb-120 { margin-bottom: 120px }
.mb-130 { margin-bottom: 130px }
.mb-140 { margin-bottom: 140px }
.mb-150 { margin-bottom: 150px }
/*************************
    12.4 Margin left 
*************************/
.ml-0 { margin-left: 0 }
.ml-10 { margin-left: 10px }
.ml-15 { margin-left: 15px }
.ml-20 { margin-left: 20px }
.ml-30 { margin-left: 30px }
.ml-40 { margin-left: 40px }
.ml-50 { margin-left: 50px }
.ml-60 { margin-left: 60px }
.ml-70 { margin-left: 70px }
.ml-80 { margin-left: 80px }
.ml-90 { margin-left: 90px }
.ml-100 { margin-left: 100px }
.ml-110 { margin-left: 110px }
.ml-120 { margin-left: 120px }
.ml-130 { margin-left: 130px }
.ml-140 { margin-left: 140px }
.ml-150 { margin-left: 150px }
/*************************
    12.5 Padding top
*************************/
.pt-0 { padding-top: 0 }
.pt-10 { padding-top: 10px }
.pt-15 { padding-top: 15px }
.pt-20 { padding-top: 20px }
.pt-30 { padding-top: 30px }
.pt-40 { padding-top: 40px }
.pt-50 { padding-top: 50px }
.pt-55 { padding-top: 55px }
.pt-60 { padding-top: 60px }
.pt-70 { padding-top: 70px }
.pt-80 { padding-top: 80px }
.pt-90 { padding-top: 90px }
.pt-100 { padding-top: 100px }
.pt-110 { padding-top: 110px }
.pt-120 { padding-top: 120px }
.pt-130 { padding-top: 130px }
.pt-140 { padding-top: 140px }
.pt-150 { padding-top: 150px }
.pt-160 { padding-top: 160px }
/*************************
    12.6 Padding right 
*************************/
.pr-0 { padding-right: 0 }
.pr-10 { padding-right: 10px }
.pr-15 { padding-right: 15px }
.pr-20 { padding-right: 20px }
.pr-30 { padding-right: 30px }
.pr-40 { padding-right: 40px }
.pr-50 { padding-right: 50px }
.pr-60 { padding-right: 60px }
.pr-70 { padding-right: 70px }
.pr-80 { padding-right: 80px }
.pr-90 { padding-right: 90px }
.pr-100 { padding-right: 100px }
.pr-110 { padding-right: 110px }
.pr-120 { padding-right: 120px }
.pr-130 { padding-right: 130px }
.pr-140 { padding-right: 140px }
/*************************
    12.7 Padding bottom  
*************************/
.pb-0 { padding-bottom: 0 }
.pb-10 { padding-bottom: 10px }
.pb-15 { padding-bottom: 15px }
.pb-20 { padding-bottom: 20px }
.pb-25 { padding-bottom: 25px }
.pb-30 { padding-bottom: 30px }
.pb-40 { padding-bottom: 40px }
.pb-50 { padding-bottom: 50px }
.pb-55 { padding-bottom: 55px }
.pb-60 { padding-bottom: 60px }
.pb-70 { padding-bottom: 70px }
.pb-80 { padding-bottom: 80px }
.pb-90 { padding-bottom: 90px }
.pb-100 { padding-bottom: 100px }
.pb-110 { padding-bottom: 110px }
.pb-120 { padding-bottom: 120px }
.pb-130 { padding-bottom: 130px }
.pb-140 { padding-bottom: 140px }
.pb-150 { padding-bottom: 150px }
.pb-160 { padding-bottom: 160px }
/*************************
    12.8 Padding left 
*************************/
.pl-0 { padding-left: 0 }
.pl-10 { padding-left: 10px }
.pl-15 { padding-left: 15px }
.pl-20 { padding-left: 20px }
.pl-30 { padding-left: 30px }
.pl-40 { padding-left: 40px }
.pl-50 { padding-left: 50px }
.pl-60 { padding-left: 60px }
.pl-70 { padding-left: 70px }
.pl-80 { padding-left: 80px }
.pl-90 { padding-left: 90px }
.pl-100 { padding-left: 100px }
.pl-110 { padding-left: 110px }
.pl-120 { padding-left: 120px }
.pl-130 { padding-left: 130px }
.pl-140 { padding-left: 140px }
.pl-150 { padding-left: 150px }
/***************************
    12.9 Page section padding   
****************************/
.ptb-0 { padding: 0 }
.ptb-10 { padding: 10px 0 }
.ptb-20 { padding: 20px 0 }
.ptb-30 { padding: 30px 0 }
.ptb-40 { padding: 40px 0 }
.ptb-50 { padding: 50px 0 }
.ptb-60 { padding: 60px 0 }
.ptb-70 { padding: 70px 0 }
.ptb-80 { padding: 80px 0 }
.ptb-90 { padding: 90px 0 }
.ptb-100 { padding: 100px 0 }
.ptb-110 { padding: 110px 0 }
.ptb-120 { padding: 120px 0 }
.ptb-130 { padding: 130px 0 }
.ptb-140 { padding: 140px 0 }
.ptb-150 { padding: 150px 0 }
.ptb-160 { padding: 160px 0 }
/***************************
    12.10 Page section margin 
****************************/
.mtb-0 { margin: 0 }
.mtb-10 { margin: 10px 0 }
.mtb-15 { margin: 15px 0 }
.mtb-20 { margin: 20px 0 }
.mtb-30 { margin: 30px 0 }
.mtb-35 { margin: 35px 0 }
.mtb-40 { margin: 40px 0 }
.mtb-50 { margin: 50px 0 }
.mtb-60 { margin: 60px 0 }
.mtb-70 { margin: 70px 0 }
.mtb-80 { margin: 80px 0 }
.mtb-90 { margin: 90px 0 }
.mtb-100 { margin: 100px 0 }
.mtb-110 { margin: 110px 0 }
.mtb-120 { margin: 120px 0 }
.mtb-130 { margin: 130px 0 }
.mtb-140 { margin: 140px 0 }
.mtb-150 { margin: 150px 0; }
.mtb-160 { margin: 160px 0; }

/*************************************************
    12.11 Extra padding & margin
*************************************************/
.plr-185 {
  padding: 0 185px;
}
.plr-200 {
  padding-left: 200px;
  padding-right: 200px
}
.ptblr-10-30 {
  padding: 10px 30px;
}
.ptblr-20-30 {
  padding: 20px 30px;
}
.p-20 {
  padding: 20px;
}
.p-30 {
  padding: 30px;
}
.mt--50 {
  margin-top: -50px;
}
/***************************
  13. Scroll Up 
****************************/
#scrollUp {
  background: #ccc;
  bottom: 10px;
  color: #ffffff;
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  right: 10px;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 40px;
}
#scrollUp:hover {
  background: #FF7F00 none repeat scroll 0 0;
  color: #ffffff;
}
/*Call to action*/
.action-box-button a.button span {
  font-size: 13px;
  padding: 11px 12px;
}
/*************************************
  14. Extra class (Custom cloumn)
*************************************/
.pricing .container .row [class*="col-"] { }
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10 {
    float: left;
}
.col-1{
    width: 10%;
}
.col-2{
    width: 20%;
}
.col-3{
    width: 30%;
}
.col-4{
    width: 40%;
}
.col-5{
    width: 50%;
}
.col-6{
    width: 60%;
}
.col-7{
    width: 70%;
}
.col-8{
    width: 80%;
}
.col-9{
    width: 90%;
}
.col-10{
    width: 100%;
}
/*************************************
  15. box-shadow 
 **************************************/
.box-shadow {  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1); }

/*************************************
  16. Background variation set 
 **************************************/
/*colored background*/
.theme-bg { background: #ff7f00; }
.black-bg { background: #222222; }
.white-bg { background: #fff; }
.gray-bg { background: #f6f6f6; }
.blue-bg { background: #4FC1F0; }
.default-bg { background: #f6f6f6; }
.transparent-bg { background: transparent; }

/*background-change with color change*/
.footer-area .theme-bg p, 
.footer-area .black-bg p,
.footer-area .theme-bg h4, 
.footer-area .black-bg h4,
.footer-area .theme-bg .footer-menu li a, 
.footer-area .black-bg .footer-menu li a,
.footer-area .theme-bg .footer-social li a, 
.footer-area .black-bg .footer-social li a { 
  color: #fff;
}
.footer-area .theme-bg .footer-social li a, 
.footer-area .black-bg .footer-social li a {
  border: 1px solid #fff;
}
.footer-area .theme-bg .footer-menu li:hover a, 
.footer-area .black-bg .footer-menu li:hover a,
.footer-area .theme-bg .footer-menu li:hover a i, 
.footer-area .black-bg .footer-menu li:hover a i {
  color: #444;
}
.footer-area .theme-bg .footer-menu li a i, 
.footer-area .black-bg .footer-menu li a i {
  color: #fff;
}
.footer-area .theme-bg .submit-btn-1, 
.footer-area .black-bg .submit-btn-1 {
  background: #fff none repeat scroll 0 0;
  color: #000;
}
/*************************************
  17. Opacity background
 **************************************/
 .bg-opacity-black-10, .bg-opacity-black-20, .bg-opacity-black-30, .bg-opacity-black-40, .bg-opacity-black-50, .bg-opacity-black-60, .bg-opacity-black-70, .bg-opacity-black-80, .bg-opacity-black-90,  .bg-opacity-white-10,  .bg-opacity-white-20,  .bg-opacity-white-30,  .bg-opacity-white-40,  .bg-opacity-white-50,  .bg-opacity-white-60,  .bg-opacity-white-70,  .bg-opacity-white-80,  .bg-opacity-white-90{
    z-index: 9; 
 }
 .bg-opacity-black-10:before, .bg-opacity-black-20:before, .bg-opacity-black-30:before, .bg-opacity-black-40:before, .bg-opacity-black-50:before, .bg-opacity-black-60:before, .bg-opacity-black-70:before, .bg-opacity-black-80:before, .bg-opacity-black-90:before,  .bg-opacity-white-10:before,  .bg-opacity-white-20:before,  .bg-opacity-white-30:before,  .bg-opacity-white-40:before,  .bg-opacity-white-50:before,  .bg-opacity-white-60:before,  .bg-opacity-white-70:before,  .bg-opacity-white-80:before,  .bg-opacity-white-90:before{
    content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; 
 }
 .bg-opacity-black-10:before {  background: rgba(0, 0, 0, 0.1);}
 .bg-opacity-black-20:before {  background: rgba(0, 0, 0, 0.2);}
 .bg-opacity-black-30:before {  background: rgba(0, 0, 0, 0.3);}
 .bg-opacity-black-40:before {  background: rgba(0, 0, 0, 0.4);}
 .bg-opacity-black-50:before {  background: rgba(0, 0, 0, 0.5);}
 .bg-opacity-black-60:before {  background: rgba(0, 0, 0, 0.6);}
 .bg-opacity-black-70:before {  background: rgba(0, 0, 0, 0.7);}
 .bg-opacity-black-80:before {  background: rgba(0, 0, 0, 0.8);}
 .bg-opacity-black-90:before {  background: rgba(0, 0, 0, 0.9);}

 .bg-opacity-white-10:before {  background: rgba(255, 255, 255, 0.1);}
 .bg-opacity-white-20:before {  background: rgba(255, 255, 255, 0.2);}
 .bg-opacity-white-30:before {  background: rgba(255, 255, 255, 0.3);}
 .bg-opacity-white-40:before {  background: rgba(255, 255, 255, 0.4);}
 .bg-opacity-white-50:before {  background: rgba(255, 255, 255, 0.5);}
 .bg-opacity-white-60:before {  background: rgba(255, 255, 255, 0.6);}
 .bg-opacity-white-70:before {  background: rgba(255, 255, 255, 0.7);}
 .bg-opacity-white-80:before {  background: rgba(255, 255, 255, 0.8);}
 .bg-opacity-white-90:before {  background: rgba(255, 255, 255, 0.9);}

/*************************************
  18. image background
 **************************************/
 .bg-1 {
  background: url(1b8fdceb3ac53c78b3d8ff31face91ac.jpg);}
 .bg-2 { background: url(1b8fdceb3ac53c78b3d8ff31face91ac.jpg);}
 .bg-3 { background: url(1b8fdceb3ac53c78b3d8ff31face91ac.jpg);}
 .bg-4 { background: url(1b8fdceb3ac53c78b3d8ff31face91ac.jpg);}
 .bg-5 { background: url(de32827c690341063cc912de9045be0e.jpg);}
 .bg-6 { background: url(1b8fdceb3ac53c78b3d8ff31face91ac.jpg);}
 .bg-7 { background: url(1b8fdceb3ac53c78b3d8ff31face91ac.jpg);}
 .bg-8 { background: url(1b8fdceb3ac53c78b3d8ff31face91ac.jpg);}
 .bg-9 { background: url(1b8fdceb3ac53c78b3d8ff31face91ac.jpg);}
 .bg-10 { background: url(1b8fdceb3ac53c78b3d8ff31face91ac.jpg);}

.bg-1, .bg-2, .bg-3, .bg-4, .bg-5, .bg-6, .bg-7, .bg-8, .bg-9, .bg-10{
    background-attachment: fixed; background-clip: initial; background-color: rgba(0, 0, 0, 0); background-origin: initial; background-position: center center; background-repeat: no-repeat; background-size: cover; position: relative; z-index: 0;
}
  /*pattern background*/
/* .pattern-bg { background:url(../../images/pattern/pattern-bg.png) repeat; background-position: top right; background-color: rgba(236, 239, 247, 1); } */
/*************************************
  19. tooltip
 **************************************/
.socile-icon-tooltip [data-tooltip], .socile-icon-tooltip .tooltip {
    cursor: pointer;
    position: relative;
}
.socile-icon-tooltip [data-tooltip]::before, .socile-icon-tooltip [data-tooltip]::after, .socile-icon-tooltip .tooltip::before, .socile-icon-tooltip .tooltip::after {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: translate3d(0px, 0px, 0px);
    transition: opacity 0.2s ease-in-out 0s, visibility 0.2s ease-in-out 0s, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24) 0s;
    visibility: hidden;
}
.socile-icon-tooltip [data-tooltip]:hover::before, .socile-icon-tooltip [data-tooltip]:hover::after, .socile-icon-tooltip [data-tooltip]:focus::before, .socile-icon-tooltip [data-tooltip]:focus::after, .socile-icon-tooltip .tooltip:hover::before, .socile-icon-tooltip .tooltip:hover::after, .socile-icon-tooltip .tooltip:focus::before, .socile-icon-tooltip .tooltip:focus::after {
    opacity: 1;
    visibility: visible;
}
.socile-icon-tooltip .tooltip::before, .socile-icon-tooltip [data-tooltip]::before {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 6px solid rgba(0, 0, 0, 0);
    content: "";
    z-index: 1001;
}
.socile-icon-tooltip .tooltip::after, .socile-icon-tooltip [data-tooltip]::after {
    background-color: #00a9da;
    border-radius: 3px;
    color: #ffffff;
    content: attr(data-tooltip);
    font-size: 14px;
    line-height: 1.2;
    padding: 8px;
    text-align: center;
    width: 100px;
    z-index: 1000;
}
.socile-icon-tooltip [data-tooltip]::before, .socile-icon-tooltip [data-tooltip]::after, .socile-icon-tooltip .tooltip::before, .socile-icon-tooltip .tooltip::after, .socile-icon-tooltip .tooltip-top::before, .socile-icon-tooltip .tooltip-top::after {
    bottom: 100%;
    left: 50%;
}
.socile-icon-tooltip [data-tooltip]::before, .socile-icon-tooltip .tooltip::before, .socile-icon-tooltip .tooltip-top::before {
    border-top-color: #00a9da;
    margin-bottom: -12px;
    margin-left: -6px;
}
.socile-icon-tooltip [data-tooltip]::after, .socile-icon-tooltip .tooltip::after, .socile-icon-tooltip .tooltip-top::after {
    margin-left: -50px;
}
.socile-icon-tooltip [data-tooltip]:hover::before, .socile-icon-tooltip [data-tooltip]:hover::after, .socile-icon-tooltip [data-tooltip]:focus::before, .socile-icon-tooltip [data-tooltip]:focus::after, .socile-icon-tooltip .tooltip:hover::before, .socile-icon-tooltip .tooltip:hover::after, .socile-icon-tooltip .tooltip:focus::before, .socile-icon-tooltip .tooltip:focus::after, .socile-icon-tooltip .tooltip-top:hover::before, .socile-icon-tooltip .tooltip-top:hover::after, .socile-icon-tooltip .tooltip-top:focus::before, .socile-icon-tooltip .tooltip-top:focus::after {
    transform: translateY(-25px);
}
/*************************************
  20. separator
 **************************************/
.separator{
    position: relative;
    display: inline-block;
    clear: both;
    background: rgba(0, 0, 0, 0.07) none repeat scroll 0 0;
    bottom: 0;
    height: 1px;
    width: 33%;
}
.separator::before {
  background: #4fc1f0 none repeat scroll 0 0;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  transition: all 0.4s ease 0s;
  width: 100px;
}
.section-title:hover .separator::before {
  width: 75%;
}
.separator i {
  background: #ECEFF7 none repeat scroll 0 0;
  color: #4fc1f0;
  display: block;
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  text-align: center;
  top: -20px;
  width: 40px;
}
.separator span {
  background: #ffffff none repeat scroll 0 0;
  border: 1px solid #4fc1f0;
  border-radius: 100%;
  display: block;
  height: 10px;
  position: absolute;
  top: -5px;
  width: 10px;
}
.separator i, .separator span {
  left: 0;
  transform: none;
}
.text-center .separator::before, .text-center .separator i, .text-center .separator span  {
  left: 50%;
  transform: translateX(-50%);
}

.text-right .separator::before, .text-right .separator i, .text-right .separator span  {
  left: auto;
  transform: none;
  right: 0;
}
/*************************
  21. Section title 1 
***********************/
.section-title {}
.section-title h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
}
.section-title h3 {
    margin-top: 0px;
    line-height: 27px;
}
.section-title p {
    margin-bottom: 0px;
    font-size: 16px;
}
.section-title .title-line { padding: 10px 0 }
.section-title .title-line:before {
    background: #4FC1F0;
    content: "";
    display: block;
    height: 2px;
    margin: 0 auto;
    width: 220px;
}
.section-title .title-line:after {
    background: #4FC1F0;
    content: "";
    display: block;
    height: 6px;
    position: relative;
    top: -6px;
    margin: 0 auto;
    width: 60px;
}
/*************************
  22. section title 2 
*************************/
.section-title-2 { margin-bottom: 60px }
.section-title-2 h1 { margin-bottom: 0px }
.section-title-2 h2 {
    margin-top: 0px;
    text-transform: capitalize;
    margin-bottom: 0px;
}
.section-title-2 p {
    margin-bottom: 0px;
    font-size: 16px;
}
.section-title-2 .title-line { padding: 20px 0 15px }
.section-title-2 .title-line:before {
    background: #4FC1F0;
    content: "";
    display: block;
    height: 2px;
    margin: 0 auto;
    width: 100px;
}
.section-title-2 .title-line-2:before {
    background: #fff;
    content: "";
    display: block;
    height: 2px;
    margin: 0 auto;
    width: 100px;
}
/*==============================================*/