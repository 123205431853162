/*--------------------------------------------------------------

  Template Name: Subash Ecommerce Responsive Bootstrap Template
  Description: This is html5 template
  Author: codecarnival
  Version: 1.0
  Design and Developed by: codecarnival

Note: This is the file where you can add your custom styles to change the look of the theme. But don't modify "style.css" file.

---------------------------------------------------------------*/

/* ----------------------------------------------------
	You can put your custom css code here: 
-------------------------------------------------------*/
