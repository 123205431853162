/*

  Template Name: Subash Ecommerce Responsive Bootstrap Template
  Description: This is html5 template
  Author: codecarnival
  Version: 1.0
  Design and Developed by: codecarnival

  Note: This is Short code style file. All Short code related style included in this file. 

*/

/* --------------------------------------------------
    Shortcode 
-------------------------------------------------- */